<template>
    <painel titulo="" :mostrarRefresh="false">
        <div class="formgrid grid border-solid border-1 surface-border border-round-md m-2 pr-2 pl-2 pt-4">
            <h3 class="field col-12 md:col-12">Confirmação de informações</h3>
            <div class="field col-4 md:col-3">
                <div class="flex flex-column">
                    <label class="mb-2">Natureza: *</label>
                    <InputText :disabled="true" v-model="natureza" />
                </div>
            </div>
            <div class="field col-4 md:col-6 drop-produtos">
                <div class="flex flex-column">
                    <label class="mb-2">Produtos: *</label>
                    <InputText :disabled="true" v-model="produto" />
                </div>
            </div>
            <div class="field col-2 md:col-3">
                <div class="flex flex-column">
                    <label class="mb-2">Valor: *</label>
                    <InputNumber :disabled="true" v-model="valor" inputId="currency-br" mode="currency" currency="BRL" locale="pt-BR" />
                </div>
            </div>
            <div class="field col-12">
                <div class="flex flex-column">
                    <label class="mb-2">Descrição:</label>
                    <Textarea :disabled="true" v-model="descricao" rows="5" cols="30" autoResize maxlength="1000" />
                </div>
            </div>
            <div class="field col-4 md:col-4">
                <div class="flex flex-column">
                    <label class="mb-2">Projeto: *</label>
                    <InputText :disabled="true" v-model="projeto" />
                </div>
            </div>
            <div class="field col-4 md:col-4">
                <div class="flex flex-column">
                    <label class="mb-2">Ação: *</label>
                    <InputText :disabled="true" v-model="acao" />
                </div>
            </div>
            <div class="field col-4 md:col-4">
                <div class="flex flex-column">
                    <label class="mb-2">Unidade Operacional: *</label>
                    <InputText :disabled="true" v-model="unidadeOperacional" />
                </div>
            </div>
            <div class="field col-4 md:col-4">
                <div class="flex flex-column">
                    <label class="mb-2">Data início</label>
                    <Calendar :disabled="true" v-model="dataInicio" :monthNavigator="true" :yearNavigator="true" :manualInput="true" showButtonBar />
                </div>
            </div>
            <div class="field col-4 md:col-4">
                <div class="flex flex-column">
                    <label class="mb-2">Data fim</label>
                    <Calendar :disabled="true" v-model="dataFim" :monthNavigator="true" :yearNavigator="true" :manualInput="true" showButtonBar />
                </div>
            </div>
            <div class="field col-4 md:col-4">
                <div class="flex flex-column">
                    <label class="mb-2">Código do evento/contrato</label>
                    <InputText :disabled="true" type="text" v-model="codEventoContrato" maxLength="100" />
                </div>
            </div>
            <div class="field col-12 md:col-12">
                <label class="mb-2 mt-2">* Campos obrigatórios</label>
            </div>
        </div>
        <div class="field col-12 md:col-12">
            <h3>Clientes</h3>
            <div v-for="(cpf, index) in cpfs" :key="index">
                <CardCpfRM :desabilitarCampoEmail="true" :cpfRM="cpf" />
            </div>
            <div class="flex mt-4 g-2">
                <Button label="Confirmar" class="mr-4" @click="confirmarVenda" />
                <Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="voltarCheckoutForm" />
            </div>
        </div>
    </painel>
    <Dialog header="Cadastro de Produto" v-model:visible="showDialog" :style="{ width: '300px' }" :breakpoints="{ '960px': '75vw', '640px': '90vw' }">
        <div class="h-full">
            <p>Deseja cadastrar outro produto?</p>
            <div class="flex justify-content-end mt-4">
                <Button label="Não" icon="pi pi-times" class="p-button-text" @click="linksPagamentos" />
                <Button label="Sim" class="mr-4" @click="voltarCheckoutForm" />
            </div>
        </div>
    </Dialog>
</template>

<script>
import CardCpfRM from './CardCpfRM.vue';
import service from './service';

export default {
    name: 'ConfirmacaoVenda',
    components: {
        CardCpfRM,
    },
    emits: ['voltar-checkout-form'],
    props: {
        dadosVenda: {
            type: Object,
        },
    },
    data() {
        return {
            natureza: {
                descricao: '',
            },
            produto: {
                descricao: '',
            },
            projeto: {
                nome: '',
            },
            acao: {
                nome: '',
            },
            unidadeOperacional: {
                nome: '',
            },
            valor: null,
            descricao: '',
            dataInicio: null,
            dataFim: null,
            codEventoContrato: null,
            cpf: null,
            cpfs: [],
            showDialog: false,
            erro: null,
        };
    },
    mounted() {
        this.preencherDadosVenda();
    },
    methods: {
        preencherDadosVenda() {
            this.valor = this.dadosVenda.valor;
            this.descricao = this.dadosVenda.descricao ? this.dadosVenda.descricao : this.descricao;
            this.dataInicio = this.$moment(this.dadosVenda.dataInicial, 'YYYY-MM-DD').toDate();
            this.dataFim = this.$moment(this.dadosVenda.dataFinal, 'YYYY-MM-DD').toDate();
            this.codEventoContrato = this.dadosVenda.codigoEvento;
            this.cpfs = this.dadosVenda.produtoCheckouts ? this.dadosVenda.produtoCheckouts : this.cpfs;
            this.natureza = this.dadosVenda.naturezaDescricao ? this.dadosVenda.naturezaDescricao : this.natureza;
            this.produto = this.dadosVenda.titulo ? this.dadosVenda.titulo : this.produto;
            this.projeto = this.dadosVenda.projetoNome ? this.dadosVenda.projetoNome : this.projeto;
            this.acao = this.dadosVenda.acaoNome ? this.dadosVenda.acaoNome : this.acao;
            this.unidadeOperacional = this.dadosVenda.unidadeOperacionalNome ? this.dadosVenda.unidadeOperacionalNome : this.unidadeOperacional;
        },
        voltarCheckoutForm() {
            this.showDialog = false;
            this.$emit('voltar-checkout-form');
        },
        linksPagamentos() {
            this.$router.push({ name: 'links-pagamentos' });
        },
        confirmarVenda() {
            this.$store.dispatch('addRequest');
            let dadosVendasDto = {
                titulo: this.dadosVenda.titulo,
                descricao: this.dadosVenda.descricao,
                valor: this.dadosVenda.valor,
                dataInicial: this.$moment(this.dadosVenda.dataInicial).format('YYYY-MM-DDTHH:mm:ss'),
                dataFinal: this.$moment(this.dadosVenda.dataFinal).format('YYYY-MM-DDTHH:mm:ss'),
                codigoEvento: this.dadosVenda.codigoEvento,
                unidadeOperacionalId: this.dadosVenda.unidadeOperacionalId,
                naturezaId: this.dadosVenda.naturezaId,
                projetoId: this.dadosVenda.projetoId,
                acaoId: this.dadosVenda.acaoId,
                produtoCheckouts: this.cpfs,
                produtoId: this.dadosVenda.produtoId,
            };
            service
                .inserirCheckout(dadosVendasDto)
                .then((response) => {
                    if (!response?.success) {
                        this.$toast.add({ severity: 'error', summary: 'Cadastro de Produto', detail: 'Erro ao cadastrar produto', life: 3000 });
                        return;
                    }
                    this.$toast.add({ severity: 'success', summary: 'Cadastro de Produto', detail: 'Cadastro realizado com sucesso!', life: 3000 });
                    setTimeout(() => {
                        this.showDialog = true;
                    }, 2000);
                })
                .catch((error) => {
                    this.erro = error;
                    this.$toast.add({ severity: 'error', summary: 'Cadastro de Produto', detail: 'Erro ao cadastrar produto', life: 3000 });
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },
    },
};
</script>

<style scoped>
::v-deep(.p-inputtext) {
    background-color: #ced0d3;
    font-weight: 500;
    color: #000;
}
</style>
