<template>
    <div class="border-solid border-1 surface-border border-round p-3 mt-3">
        <div class="flex flex-column">
            <span class="font-bold">{{ cpfRM?.nome }}</span>
            <span class="font-bold">{{ cpfRM?.cpf }}</span>
        </div>
        <div class="flex flex-column mt-2">
            <Chips
                :disabled="desabilitarCampoEmail"
                separator=","
                addOnBlur
                class="flex"
                type="text"
                v-model="listaEmails"
                @add="atualizarEmail()"
                @remove="removeEmail()"
                @blur="v$.listaEmails.$touch()"
            />
            <small class="p-error" v-if="v$.listaEmails.$error">E-mail é obrigatório ou Inválido</small>
            <span class="text-sm mt-1">* Para mais de um e-mail, separe-os por vírgula (,)</span>
        </div>
    </div>
</template>

<script>
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

export default {
    data() {
        return {
            cliente: {
                email: '',
                cpfCnpj: '',
            },
            listaEmails: [],
            stringEmails: '',
        };
    },
    emits: ['atualizar-email'],

    setup() {
        return { v$: useVuelidate() };
    },

    validations() {
        return {
            listaEmails: { required },
        };
    },
    props: {
        cpfRM: {
            type: Object,
        },
        desabilitarCampoEmail: {
            type: Boolean,
            default: false,
        },
    },
    watch: {
        cpfRM: {
            handler() {
                this.stringEmails = this.listaEmails.push(this.cpfRM?.email);
                this.cliente = {
                    email: this.stringEmails,
                    cpfCnpj: this.cpfRM?.cpf,
                };
            },
            immediate: true,
        },
    },
    methods: {
        atualizarEmail() {
            this.stringEmails = this.listaEmails.join(',');
            this.cliente = {
                email: this.stringEmails,
                cpfCnpj: this.cpfRM?.cpf,
            };
            this.$emit('atualizar-email', this.cliente);
        },

        removeEmail() {
            this.listaEmails = this.listaEmails.filter((email) => email !== '');
            this.stringEmails = this.listaEmails.join(',');
            this.cliente = {
                email: this.stringEmails,
                cpfCnpj: this.cpfRM?.cpf,
            };
            this.$emit('atualizar-email', this.cliente);
        },
    },
};
</script>
