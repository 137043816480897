import { axiosJwt } from '@/shared/auth/axiosJwt';

const api = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CHECKOUT_PORT}${process.env.VUE_APP_API_PATH}/checkout`;

export default {
    consultarCep(cep) {
        return axiosJwt.get(`${api}/brasil/cep/${cep}`);
    },

    consultarCpf(cpf) {
        return axiosJwt.get(`${api}/sas/cpf/${cpf}`);
    },

    consultarCnpj(cnpj) {
        return axiosJwt.get(`${api}/cpe/cnpj/${cnpj}`);
    },

    salvarDados(dados) {
        return axiosJwt.post(`${api}/cadastrar-cliente`, dados);
    },
};
