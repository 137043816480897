<template>
    <section titulo="Cadastro de Dados Pessoais" class="p-3">
        <!-- <Divider /> -->
        <div class="grid p-fluid mb-3">
            <p class="text-2xl mb-2 ml-3 text-primary">Meus dados</p>
        </div>

        <div class="formgrid grid p-fluid">
            <div class="field col-12 md:col-4">
                <label class="required" for="cpf">CPF</label>
                <div class="p-inputgroup">
                    <InputMask v-model="cpf" @blur="v$.cpf.$touch()" mask="999.999.999.99" :unmask="true" />
                    <Button class="p-button-default" icon="pi pi-search" label="Consultar no SAS" @click="consultarCpf" />
                </div>
                <small class="p-error" v-if="v$.cpf.required.$invalid && v$.cpf.$error">O CPF é obrigatório</small>
                <small
                    class="p-error"
                    v-if="
                        (v$.cpf.cpfValido.$invalid && !(v$.cpf.required.$invalid || v$.cpf.minLength.$invalid)) || (v$.cpf.minLength.$invalid && v$.cpf.$error)
                    "
                    >CPF inválido</small
                >
            </div>
            <div class="field col-12 md:col-4">
                <label class="required" for="">Nome Completo</label>
                <InputText type="text" @blur="v$.nome.$touch()" v-model="nome" />
                <small class="p-error" v-if="v$.nome.$error">O nome é obrigatório</small>
            </div>
            <div class="field col-12 md:col-4">
                <label class="required" for="">E-mail</label>
                <InputText type="email" @input="v$.email.$touch()" v-model="email" />
                <small class="p-error" v-if="v$.email.$error">O e-mail é obrigatório</small>
            </div>
        </div>

        <div class="formgrid grid p-fluid">
            <div class="field col-12 md:col-2">
                <label for="dataNasc">Data de Nasc.</label>
                <Calendar
                    v-model="dataNasc"
                    :monthNavigator="true"
                    :yearNavigator="true"
                    :class="{ 'p-error': v$.dataNasc.$error }"
                    @date-select="v$.dataNasc.$touch()"
                    :manualInput="false"
                    showIcon
                    dateFormat="dd/mm/yy"
                    :maxDate="new Date()"
                />
                <small class="p-error" v-if="v$.dataNasc.$error">
                    <span v-if="v$.dataNasc.required.$invalid">A data de nascimento é obrigatória</span>
                    <span v-if="v$.dataNasc.dataValida.$invalid">Forneça uma data válida</span></small
                >
            </div>
        </div>
        <div class="formgrid grid p-fluid">
            <div class="field col-12 md:col-3">
                <label class="required" for="telefone">Telefone</label>
                <InputMask @blur="v$.telefone.$touch()" mask="(99) 99999-999?9" :unmask="true" v-model="telefone" />
                <small class="p-error" v-if="v$.telefone.$error">O telefone é obrigatório</small>
            </div>
        </div>

        <div class="grid p-fluid mt-1">
            <p class="text-sm text-500 ml-3">
                * Campo de preenchimento obrigatório <br />
                <!-- ** Pelo menos um dos campos deverá ser preenchido -->
            </p>
        </div>

        <Divider />
        <div class="grid p-fluid mt-3">
            <p class="text-2xl mb-2 ml-3 text-primary">Endereço</p>
        </div>

        <div class="formgrid grid p-fluid">
            <div class="field col-12 md:col-2">
                <label class="required" for="cep">CEP</label>
                <span class="btn-correios" @click="consultarCepExterno">
                    Correios
                    <span class="pi pi-external-link"></span>
                </span>
                <InputMask @blur="v$.cep.$touch()" v-model="cep" @change="consultarCep" mask="99.999-999" :unmask="true" />
                <small class="p-error" v-if="v$.cep.$error">O CEP é obrigatório</small>
            </div>
            <div class="field col-12 md:col-4">
                <label class="required" for="logradouro">Logradouro</label>
                <InputText @blur="v$.logradouro.$touch()" v-model="logradouro" type="text" />
                <small class="p-error" v-if="v$.logradouro.$error">O logradouro é obrigatório</small>
            </div>
            <div class="field col-12 md:col-6">
                <label for="complemento">Número / Complemento</label>
                <InputText v-model="complemento" type="text" />
            </div>
        </div>

        <div class="formgrid grid p-fluid">
            <div class="field col-12 md:col-5">
                <label class="required" for="bairro">Bairro</label>
                <InputText @blur="v$.bairro.$touch()" v-model="bairro" type="text" />
                <small class="p-error" v-if="v$.bairro.$error">O bairro é obrigatório</small>
            </div>
            <div class="field col-12 md:col-4">
                <label class="required" for="cidade">Cidade</label>
                <InputText @blur="v$.cidade.$touch()" v-model="cidade" type="text" />
                <small class="p-error" v-if="v$.cidade.$error">A cidade é obrigatória</small>
            </div>
            <div class="field col-12 md:col-3">
                <label class="required">UF</label>
                <Dropdown @blur="v$.estado.$touch()" v-model="estado" :options="estados" optionLabel="text" optionValue="value" />
                <small class="p-error" v-if="v$.estado.$error">A UF é obrigatória</small>
            </div>
        </div>
        <div class="grid p-fluid mt-3 col-12 md:col-12 text-sm w-2">
            <Button :disabled="v$.$invalid" label="Salvar" @click="salvarDados" />
        </div>
    </section>
    <router-view></router-view>
</template>

<script>
import Services from './services';
import useVuelidate from '@vuelidate/core';
import { required, minLength, email } from '@vuelidate/validators';
import { estados } from '../../../../src/shared/components/estados';

export default {
    props: {
        cpfProp: {
            type: String,
            required: true,
        },
    },

    setup() {
        return { v$: useVuelidate({ $stopPropagation: true, $scope: 'ClienteForm' }) };
    },

    emits: ['clienteCadastrado'],

    data() {
        return {
            cpf: null,
            nome: null,
            email: null,
            dataNasc: null,
            telefone: null,
            logradouro: null,
            complemento: null,
            estado: null,
            cidade: null,
            bairro: null,
            cep: null,
            generos: [
                { nome: 'Feminino', cod: '1' },
                { nome: 'Masculino', cod: '2' },
                { nome: 'Prefiro não informar', cod: '0' },
            ],
            erros: [],
            estados: estados,
            error: null,
        };
    },

    validations() {
        return {
            cpf: {
                required,
                minLength: minLength(10),
                cpfValido: () => {
                    return this.$validarCPF(this.cpf);
                },
            },
            nome: { required },
            email: { required, email },
            dataNasc: { required },
            telefone: { required },
            logradouro: { required },
            estado: { required },
            cidade: { required },
            bairro: { required },
            cep: { required },
        };
    },

    mounted() {
        this.popularCpf();
    },

    methods: {
        popularCpf() {
            this.cpf = this.cpfProp;
        },

        consultarCpf() {
            this.$store.dispatch('addRequest');
            Services.consultarCpf(this.cpf)
                .then((response) => {
                    if (!response?.data) {
                        this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Cadastro não encontrado', life: 3000 });
                        return;
                    }
                    this.preencherForm(response.data);
                })
                .catch((error) => {
                    this.error = error;
                    this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Erro ao consultar CPF', life: 3000 });
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        consultarCep() {
            this.$store.dispatch('addRequest');
            Services.consultarCep(this.cep).then((response) => {
                if (!response?.success) {
                    this.$store.dispatch('removeRequest');
                    this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'CEP não encontrado', life: 3000 });
                    return;
                }
                this.logradouro = response.data.street;
                this.complemento = response.data.complemento;
                this.bairro = response.data.neighborhood;
                this.cidade = response.data.city;
                this.estado = response.data.state;
                this.$store.dispatch('removeRequest');
            });
        },

        consultarCepExterno() {
            window.open('https://buscacepinter.correios.com.br/app/endereco/index.php', '_blank');
        },

        salvarDados() {
            let clienteDto = {
                cpf: this.cpf,
                nome: this.nome,
                email: this.email,
                dataNasc: this.dataNasc,
                telefone: this.telefone,
                logradouro: this.logradouro,
                complemento: this.complemento,
                estado: this.estado,
                cidade: this.cidade,
                bairro: this.bairro,
                cep: this.cep,
            };
            this.$store.dispatch('addRequest');
            Services.salvarDados(clienteDto).then((response) => {
                if (response.success) {
                    this.$store.dispatch('removeRequest');
                    this.$toast.add({ severity: 'success', summary: 'Sucesso', detail: 'Dados salvos com sucesso', life: 3000 });
                    this.$emit('clienteCadastrado');
                    return;
                }
                this.$store.dispatch('removeRequest');
                this.$toast.add({ severity: 'error', summary: 'Erro', detail: 'Erro ao salvar dados', life: 3000 });
            });
        },
        preencherForm(cliente) {
            this.nome = cliente.nome;
            this.dataNasc = cliente.dataNasc ? new Date(cliente.dataNasc) : null;
            this.email = cliente.email;
            this.telefone = cliente.telefone;
            this.logradouro = cliente.logradouro;
            this.complemento = cliente.complemento;
            this.estado = cliente.estado;
            this.cidade = cliente.cidade;
            this.bairro = cliente.bairro;
            this.cep = cliente.cep;
        },
    },
};
</script>

<style scoped>
.btn-correios {
    background-color: #00416b;
    border-radius: 5px;
    color: #ffd400;
    cursor: pointer;
    margin-left: 10px;
    padding: 4px 8px 4px 4px;
}
</style>
