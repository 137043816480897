<template>
    <painel titulo="" :mostrarRefresh="false">
        <div class="formgrid grid col-12 border-solid border-1 surface-border border-round-md m-2 pr-2 pl-2 pt-4">
            <h3 class="field col-12 md:col-12">Cadastro produto</h3>
            <div class="field col-12 md:col-3">
                <div class="flex flex-column">
                    <label class="mb-2">Natureza: *</label>
                    <Dropdown
                        id="natureza"
                        optionLabel="descricao"
                        v-model="naturezaSelecionada"
                        filter
                        optionValue="codigo"
                        :options="naturezas"
                        @blur="v$.naturezaSelecionada.$touch()"
                    ></Dropdown>
                    <small class="p-error mt-2" v-if="v$.naturezaSelecionada.$error">Campo obrigatório</small>
                </div>
            </div>
            <div class="field col-12 md:col-6">
                <div class="flex flex-column">
                    <label class="mb-2">Produtos: *</label>
                    <Dropdown
                        class="produtos"
                        id="produto"
                        optionLabel="descricaoDetalhe"
                        v-model="produtoSelecionado"
                        filter
                        editable
                        :options="produtos"
                        optionvalue="produtoId"
                        @blur="v$.produtoSelecionado.$touch(), aplicaStyles()"
                    ></Dropdown>
                    <!-- <Input @novo-produto="novoProduto" /> -->
                    <small class="p-error mt-2" v-if="v$.produtoSelecionado.$error">Campo obrigatório</small>
                </div>
            </div>
            <div class="field col-12 md:col-3">
                <div class="flex flex-column">
                    <label class="mb-2">Valor: *</label>
                    <InputNumber
                        :disabled="!naturezaSelecionada"
                        v-model="valor"
                        inputId="currency-br"
                        mode="currency"
                        currency="BRL"
                        locale="pt-BR"
                        @input="oninput($event)"
                    />
                    <small class="p-error mt-1" v-if="v$.valor.$error">Campo obrigatório</small>
                </div>
            </div>
            <div class="field col-12">
                <div class="flex flex-column">
                    <label class="mb-2">Descrição:</label>
                    <Textarea :disabled="!naturezaSelecionada" v-model="descricao" rows="5" cols="30" autoResize maxlength="200" />
                </div>
            </div>
            <div class="field col-12 md:col-4">
                <div class="flex flex-column">
                    <label class="mb-2">Unidade Operacional: *</label>
                    <Dropdown
                        @blur="v$.unidOperacSelecionada.$touch()"
                        :disabled="!naturezaSelecionada"
                        optionLabel="nome"
                        v-model="unidOperacSelecionada"
                        filter
                        :options="unidadesOperacionais"
                        optionValue="codUnidade"
                    ></Dropdown>
                    <small class="p-error mt-1" v-if="v$.unidOperacSelecionada.$error">Campo obrigatório</small>
                </div>
            </div>
            <div class="field col-12 md:col-4">
                <div class="flex flex-column">
                    <label class="mb-2">Projeto: *</label>
                    <Dropdown
                        :disabled="!naturezaSelecionada"
                        optionLabel="nome"
                        v-model="projetoSelecionado"
                        filter
                        :options="projetos"
                        optionValue="codProjeto"
                        @blur="v$.projetoSelecionado.$touch(), aplicaStyles()"
                    ></Dropdown>
                    <small class="p-error mt-1" v-if="v$.projetoSelecionado.$error">Campo obrigatório</small>
                </div>
            </div>
            <div class="field col-12 md:col-4">
                <div class="flex flex-column">
                    <label class="mb-2">Ação: *</label>
                    <Dropdown
                        :disabled="desabilitarAcao"
                        @blur="v$.acaoSelecionada.$touch()"
                        optionLabel="nome"
                        v-model="acaoSelecionada"
                        filter
                        :options="acoes"
                        optionValue="codAcao"
                    ></Dropdown>
                    <small class="p-error mt-1" v-if="v$.acaoSelecionada.$error">Campo obrigatório</small>
                </div>
            </div>
            <div class="field col-12 md:col-4">
                <div class="flex flex-column">
                    <label class="mb-2">Data início</label>
                    <Calendar
                        :disabled="!naturezaSelecionada"
                        v-model="dataInicial"
                        placeholder="dd/mm/aaaa"
                        :monthNavigator="true"
                        :yearNavigator="true"
                        showIcon
                        :manualInput="true"
                        @date-select="v$.dataInicial.$touch()"
                    />
                    <small class="p-error flex flex-column" v-if="v$.dataInicial.$error">
                        <span class="mt-1" v-if="v$.dataInicial.dataMenorQueFinal.$invalid">{{ v$.dataInicial.dataMenorQueFinal.$message }}</span>
                    </small>
                </div>
            </div>
            <div class="field col-12 md:col-4">
                <div class="flex flex-column">
                    <label class="mb-2">Data fim</label>
                    <Calendar
                        :disabled="!naturezaSelecionada"
                        v-model="dataFinal"
                        placeholder="dd/mm/aaaa"
                        :monthNavigator="true"
                        :yearNavigator="true"
                        showIcon
                        :manualInput="true"
                        @date-select="v$.dataFinal.$touch()"
                    />
                    <small class="p-error flex flex-column" v-if="v$.dataFinal.$error">
                        <span class="mt-1" v-if="v$.dataFinal.dataMaiorQueInicial.$invalid">{{ v$.dataFinal.dataMaiorQueInicial.$message }}</span>
                    </small>
                </div>
            </div>
            <div class="field col-12 md:col-4">
                <div class="flex flex-column">
                    <label class="mb-2">Código do evento/contrato</label>
                    <InputText :disabled="!naturezaSelecionada" type="text" v-model="codEventoContrato" maxLength="100" />
                </div>
            </div>
            <div class="field col-12 md:col-12">
                <label class="mb-2 mt-2">* Campos obrigatórios</label>
            </div>
        </div>

        <div class="field col-12 md:col-12">
            <div class="flex flex-column">
                <label class="mb-2">CPF</label>
                <div class="flex">
                    <InputMask :disabled="desabilitarCpf" placeholder="999.999.999-99" class="mr-3" mask="999.999.999-99" v-model="cpf" :unmask="true" />
                    <Button :disabled="desabilitarBotaoCpf" icon="pi pi-plus" @click="verificarCpf" />
                </div>
                <small class="p-error" v-if="v$.cpf.cpfValido.$invalid && v$.cpf.$error">CPF inválido</small>
            </div>
            <div v-for="(cpf, index) in checkouts" :key="index">
                <CardCpfRM @atualizar-email="atualizarEmail" :cpfRM="cpf" />
            </div>
            <div class="flex mt-4 g-2">
                <Button :disabled="checkouts.length === 0 || !checkouts[0]?.email" class="mr-4" label="Publicar" icon="pi pi-upload" @click="publicar" />
                <Button label="Limpar" icon="pi pi-times" class="p-button-text" @click="limparCampos" />
            </div>
        </div>
    </painel>
    <Dialog v-model:visible="showDialogClientForm" @hide="showDialogClientForm = false" :style="{ width: '80vw' }">
        <ClienteForm :cpfProp="cpf" @clienteCadastrado="clienteCadastrado()"></ClienteForm>
    </Dialog>
</template>

<script>
import service from './service';
import { helpers, required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import CardCpfRM from './CardCpfRM.vue';
import ClienteForm from '../cadastroclientes/ClienteForm.vue';

export default {
    name: 'GerarLinkProdutoForm',
    components: {
        CardCpfRM,
        ClienteForm,
    },
    setup: () => ({ v$: useVuelidate({ $stopPropagation: true, $scope: 'CheckoutVendaForm' }) }),

    emits: ['publicar'],

    props: {
        dadosVenda: {
            type: Object,
        },
    },

    data() {
        return {
            titulo: null,
            naturezas: [],
            produtos: [],
            projetos: [],
            acoes: [],
            unidadesOperacionais: [],
            naturezaSelecionada: null,
            produtoSelecionado: null,
            projetoSelecionado: null,
            acaoSelecionada: null,
            unidOperacSelecionada: null,
            valor: null,
            descricao: null,
            dataInicial: new Date(),
            dataFinal: new Date(),
            codEventoContrato: null,
            cpf: null,
            checkouts: [],
            mostrarModalProduto: false,
            inserirNoZero: false,
            valorFilter: null,
            showDialogClientForm: false,
            error: null,
        };
    },
    validations() {
        return {
            naturezaSelecionada: { required },
            produtoSelecionado: { required },
            valor: { required },
            projetoSelecionado: { required },
            acaoSelecionada: { required },
            unidOperacSelecionada: { required },
            dataInicial: {
                dataMenorQueFinal: helpers.withMessage('Não pode ser maior que a data final.', (val) => {
                    const dataInicial = this.$moment(val, 'DD/MM/YYYY');
                    const dataFinal = this.$moment(this.dataFinal, 'DD/MM/YYYY');
                    if (!dataFinal.isValid()) return true;
                    return dataInicial.isSameOrBefore(dataFinal);
                }),
            },
            dataFinal: {
                dataMaiorQueInicial: helpers.withMessage('Não pode ser menor que a data de início.', (val) => {
                    const dataFinal = this.$moment(val, 'DD/MM/YYYY');
                    const dataInicial = this.$moment(this.dataInicial, 'DD/MM/YYYY');
                    return dataFinal.isSameOrAfter(dataInicial);
                }),
            },
            cpf: {
                cpfValido: () => {
                    return this.$validarCPF(this.cpf);
                },
            },
        };
    },
    mounted() {
        this.obterNaturezas();
        this.obterProjetos();
        this.obterUnidadesOperacionais();
        this.obterProdutos();
    },
    computed: {
        desabilitarAcao() {
            return !(this.naturezaSelecionada && this.projetoSelecionado && this.unidOperacSelecionada);
        },
        desabilitarCpf() {
            return !(
                this.unidOperacSelecionada &&
                this.naturezaSelecionada &&
                this.projetoSelecionado &&
                this.acaoSelecionada &&
                this.produtoSelecionado &&
                this.valor
            );
        },
        desabilitarBotaoCpf() {
            if (!this.cpf) return true;
            return this.cpf?.length !== 11;
        },
    },
    watch: {
        projetoSelecionado() {
            if (this.projetoSelecionado && this.unidOperacSelecionada) this.obterAcoes();
        },
        unidOperacSelecionada() {
            if (this.projetoSelecionado && this.unidOperacSelecionada) this.obterAcoes();
        },
        produtoSelecionado() {
            if (this.produtoSelecionado?.produtoId) this.preencherCampos(this.produtoSelecionado);
        },
    },
    methods: {
        oninput(event) {
            if (!event.value) {
                this.v$.valor.$touch();
                return;
            }
            this.v$.valor.$reset();
        },

        obterNaturezas() {
            this.$store.dispatch('addRequest');
            service
                .obterNaturezas()
                .then((response) => {
                    this.naturezas = response.data;
                })
                .catch((error) => {
                    this.error = error;
                    this.$toast.add({ severity: 'error', summary: 'Natureza', detail: 'Erro ao obter Naturezas', life: 3000 });
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        obterProjetos() {
            this.$store.dispatch('addRequest');
            service
                .obterProjetos()
                .then((response) => {
                    this.projetos = response.data;
                })
                .catch((error) => {
                    this.error = error;
                    this.$toast.add({ severity: 'error', summary: 'Projetos', detail: 'Erro ao obter Projetos', life: 3000 });
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        obterAcoes() {
            this.$store.dispatch('addRequest');
            service
                .obterAcoes(this.projetoSelecionado, this.unidOperacSelecionada)
                .then((response) => {
                    this.acoes = response.data;
                })
                .catch((error) => {
                    this.error = error;
                    this.$toast.add({ severity: 'error', summary: 'Ações', detail: 'Erro ao obter Ações', life: 3000 });
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        obterUnidadesOperacionais() {
            this.$store.dispatch('addRequest');
            service
                .obterUnidadesOperacionais()
                .then((response) => {
                    this.unidadesOperacionais = response.data;
                })
                .catch((error) => {
                    this.error = error;
                    this.$toast.add({ severity: 'error', summary: 'Unidades Operacionais', detail: 'Erro ao obter Unidades Operacionais', life: 3000 });
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        obterProdutos() {
            this.$store.dispatch('addRequest');
            service
                .obterProdutos()
                .then((response) => {
                    this.produtos = response.data;
                })
                .catch((error) => {
                    this.error = error;
                    this.$toast.add({ severity: 'error', summary: 'Produtos', detail: 'Erro ao obter Produtos', life: 3000 });
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        clienteCadastrado() {
            this.showDialogClientForm = false;
            this.verificarCpf();
        },

        verificarCpf() {
            this.$store.dispatch('addRequest');
            service
                .verificarCpf(this.cpf)
                .then((response) => {
                    if (!response.data) {
                        this.$store.dispatch('removeRequest');
                        this.$confirm.require({
                            message: 'CPF não localizado, deseja realizar o cadastro?',
                            header: 'Confirmação',
                            icon: 'pi pi-exclamation-triangle',
                            accept: () => {
                                this.showDialogClientForm = true;
                            },
                        });
                        return;
                    }
                    this.checkouts.push(response.data);
                    this.cpf = null;
                })
                .catch((error) => {
                    this.error = error;
                    this.$toast.add({ severity: 'error', summary: 'CPF', detail: 'Erro ao verificar CPF no RM', life: 3000 });
                })
                .finally(() => {
                    this.$store.dispatch('removeRequest');
                });
        },

        limparCampos() {
            this.naturezaSelecionada = null;
            this.produtoSelecionado = null;
            this.valor = null;
            this.descricao = null;
            this.projetoSelecionado = null;
            this.acaoSelecionada = null;
            this.unidOperacSelecionada = null;
            this.dataInicial = null;
            this.dataFinal = null;
            this.codEventoContrato = null;
            this.cpf = null;
            this.checkouts = [];
            this.v$.$reset();
        },

        replacecheckouts(checkouts) {
            return checkouts.map((checkouts) => {
                return {
                    ...checkouts,
                    cpf: checkouts.cpf.replace(/\./g, '').replace('-', ''),
                };
            });
        },

        publicar() {
            let checkouts = this.replacecheckouts(this.checkouts);
            let dados = {
                produtoId: this.produtoSelecionado.produtoId ? this.produtoSelecionado.produtoId : null,
                titulo: this.produtoSelecionado?.titulo ? this.produtoSelecionado?.titulo : this.produtoSelecionado,
                descricao: this.descricao,
                valor: this.valor,
                dataInicial: this.$moment(this.dataInicial).format('YYYY-MM-DDTHH:mm:ss'),
                dataFinal: this.$moment(this.dataFinal).format('YYYY-MM-DDTHH:mm:ss'),
                codigoEvento: this.codEventoContrato,
                unidadeOperacionalId: this.unidOperacSelecionada,
                naturezaId: this.naturezaSelecionada,
                projetoId: this.projetoSelecionado,
                acaoId: this.acaoSelecionada,
                produtoCheckouts: checkouts,
                naturezaDescricao: this.naturezas.find((x) => x.codigo === this.naturezaSelecionada).descricao,
                projetoNome: this.projetos.find((x) => x.codProjeto === this.projetoSelecionado).nome,
                acaoNome: this.acoes.find((x) => x.codAcao === this.acaoSelecionada).nome,
                unidadeOperacionalNome: this.unidadesOperacionais.find((x) => x.codUnidade === this.unidOperacSelecionada).nome,
            };
            this.$emit('publicar', dados);
        },

        atualizarEmail(cliente) {
            this.checkouts.forEach((cpf) => {
                if (cpf.cpf == cliente.cpfCnpj) {
                    cpf.email = cliente.email;
                }
            });
        },

        preencherCampos(produto) {
            this.naturezaSelecionada = produto.naturezaId;
            this.produtoSelecionado = produto;
            this.valor = produto.valor;
            this.descricao = produto.descricao;
            this.projetoSelecionado = produto.codProjeto;
            this.unidOperacSelecionada = produto.codUnidade;
            this.acaoSelecionada = produto.codAcao;
            this.dataInicial = produto.dataInicial ? this.$moment(produto.dataInicial, 'YYYY-MM-DD').toDate() : new Date();
            this.dataFinal = produto.dataFinal ? this.$moment(produto.dataFinal, 'YYYY-MM-DD').toDate() : new Date();
            this.codEventoContrato = produto.codigoEvento;
        },

        aplicaStyles() {
            const dropdownPanel = document.querySelector('.p-dropdown-panel');
            if (dropdownPanel) {
                dropdownPanel.style.minWidth = '94%';
                dropdownPanel.style.maxWidth = '94%';
                dropdownPanel.style.margin = '0 auto';

                const dropdownItems = dropdownPanel.querySelectorAll('.p-dropdown-item');
                dropdownItems.forEach((item) => {
                    item.style.overflow = 'visible';
                });
            }
        },
    },
};
</script>
