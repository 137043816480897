import { axiosJwt } from '@/shared/auth/axiosJwt';

const apiBase = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_CHECKOUT_PORT}${process.env.VUE_APP_API_PATH}/checkout`;

export default {
    obterNaturezas() {
        return axiosJwt.get(`${apiBase}/tabelas/natureza`);
    },
    obterProdutos() {
        return axiosJwt.get(`${apiBase}/produtos`);
    },
    obterProjetos() {
        return axiosJwt.get(`${apiBase}/tabelas/projetos`);
    },
    obterAcoes(codProjeto, codUnidade) {
        return axiosJwt.get(`${apiBase}/tabelas/acoes?codProjeto=${codProjeto}&codUnidade=${codUnidade}`);
    },
    obterUnidadesOperacionais() {
        return axiosJwt.get(`${apiBase}/tabelas/unidadesOperacionais`);
    },
    verificarCpf(cpf) {
        return axiosJwt.get(`${apiBase}/tabelas/verificarCpf?cpf=${cpf}`);
    },
    inserirCheckout(checkout) {
        return axiosJwt.post(`${apiBase}/produtos/inserir`, checkout);
    },
};
