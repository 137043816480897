<template>
    <CheckoutVendaForm :dadosVenda="dadosVenda" @publicar="confirmarVenda" v-if="!vendaPublicada" />
    <ConfirmacaoVenda @voltar-checkout-form="voltarForm" :dadosVenda="dadosVenda" v-if="vendaPublicada" />
</template>

<script>
import CheckoutVendaForm from './CheckoutVendaForm.vue';
import ConfirmacaoVenda from './ConfirmacaoVenda.vue';

export default {
    name: 'CheckoutVendas',
    components: {
        CheckoutVendaForm,
        ConfirmacaoVenda,
    },
    data() {
        return {
            vendaPublicada: false,
            dadosVenda: null,
        };
    },
    methods: {
        confirmarVenda(dadosVenda) {
            this.dadosVenda = dadosVenda;
            this.vendaPublicada = true;
        },
        voltarForm() {
            this.vendaPublicada = false;
        },
    },
};
</script>
